<template>
  <div class="bg-white rounded">
    <div class="px-4 mt-2">
      <TitlePlus
        :title="
          $t('components.vehicleDetailsManagement.diveIntoTab.notes.headline')
        "
        @plus="onAddEdit({ mode: 'add' })"
        :hide-plus="$acl.canNotCreate('vehicles')"
        class="mb-10"
      />
    </div>

    <div class="flex flex-col px-4 mt-4 mb-10 md:items-center md:flex-row">
      <SummaryCard
        :title="
          $t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.summary.totalNotes'
          )
        "
        :value="indexMetaData.count.total"
        variant="gray"
      />
    </div>
    <template>
      <div class="full-mode">
        <FSTable
          :fst-id="`vehicleNotes`"
          :endpoint="getEndpoint"
          :headers="tableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <FSTableRow
              v-for="(item, itemIndex) in data"
              :key="itemIndex"
              text-fallback-always
            >
              <FSTableRowItem :text="item.id" :truncate="-5" />

              <FSTableRowItem :text="item.details" />

              <FSTableRowItem :text="item.created_by.full_name" />

              <FSTableRowItem :text="item.created_by.role_name" />

              <FSTableRowItem :text="item.created_at" date />

              <FSTableRowItem>
                <div class="flex justify-end">
                  <TDropdown variant="smActions" :key="`actions-${item.id}`">
                    <template
                      #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                    >
                      <button
                        class="w-5 app-form-reset"
                        aria-label="Report Status Menu"
                        aria-haspopup="true"
                        @mousedown="mousedownHandler"
                        @focus="focusHandler"
                        @blur="blurHandler"
                        @keydown="keydownHandler"
                      >
                        <i class="text-sm text-gray-400 fas fa-ellipsis-v"></i>
                      </button>
                    </template>

                    <template #default>
                      <div class="py-1 bg-white rounded shadow">
                        <div
                          class="py-2 px-4 text-sm text-red-500 cursor-pointer hover:bg-red-100"
                          @click="onDelete({ noteId: item.id })"
                        >
                          Delete
                        </div>
                      </div>
                    </template>
                  </TDropdown>
                </div>
              </FSTableRowItem>
            </FSTableRow>
          </template>
        </FSTable>
      </div>
      <div class="responsive-mode">
        <FSTable
          :fst-id="`vehicleNotes`"
          :endpoint="getEndpoint"
          :headers="resTableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <FSTableRow
                :key="`fs-table-row-${itemIndex}`"
                text-fallback-always
              >
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem :text="item.details" />

                <FSTableRowItem :text="item.created_by.full_name" />

                <FSTableRowItem :text="item.created_at" date />
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Id</div>
                    <div class="col-span-5 right-text">
                      {{ truncate(item.id, -5, '#') || `--` }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Created Role</div>
                    <div class="col-span-5 right-text">
                      {{ item.created_by.role_name || `--` }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Actions</div>
                    <div class="col-span-5 right-text">
                      <div class="flex justify-start">
                        <TDropdown
                          variant="smActions"
                          :key="`actions-${item.id}`"
                        >
                          <template
                            #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                          >
                            <button
                              class="w-5 app-form-reset"
                              aria-label="Report Status Menu"
                              aria-haspopup="true"
                              @mousedown="mousedownHandler"
                              @focus="focusHandler"
                              @blur="blurHandler"
                              @keydown="keydownHandler"
                            >
                              <i
                                class="text-sm text-gray-400 fas fa-ellipsis-v"
                              ></i>
                            </button>
                          </template>

                          <template #default>
                            <div class="py-1 bg-white rounded shadow">
                              <div
                                class="py-2 px-4 text-sm text-red-500 cursor-pointer hover:bg-red-100"
                                @click="onDelete({ noteId: item.id })"
                              >
                                Delete
                              </div>
                            </div>
                          </template>
                        </TDropdown>
                      </div>
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </FSTable>
      </div>
    </template>

    <TModal name="addEditNote" variant="popupAlt" @opened="onAddEditOpened">
      <div class="pl-4 mt-4 text-lg font-bold">
        {{
          $t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.addEdit.title'
          )
        }}
      </div>

      <div class="px-4 pt-10 pb-12">
        <SweetMark
          ref="sweetMarkRef"
          v-model="addEdit.model"
          :placeholder="
            $t(
              'components.vehicleDetailsManagement.diveIntoTab.notes.addEdit.placeholder'
            )
          "
          persistent-button
          @save="
            onSaveNote({ note: $event, entityId: reqId, entityName: 'bike' })
          "
        />
      </div>
    </TModal>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'

import TitlePlus from '@/components/ui/TitlePlus'
import SweetMark from '@/components/form/SweetMark.vue'
import SummaryCard from '@/components/cards/SummaryCard'
import { truncate } from '@/plugins/truncate'
export default {
  name: 'VehicleNoteIndex',

  components: {
    SummaryCard,
    FSTable,
    FSTableRow,
    FSTableRowItem,

    TitlePlus,
    SweetMark,
  },

  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
      },

      tableHeaders: [
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.table.columns.id'
          ),
          width: '10%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.table.columns.note'
          ),
          width: '30%',
          sort: 'rider',
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.table.columns.createdBy'
          ),
          width: '15%',
          sort: 'date',
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.table.columns.creatorRole'
          ),
          width: '15%',
          sort: 'date',
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.table.columns.createdAt'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.table.columns.actions'
          ),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.table.columns.note'
          ),
          width: '20%',
          sort: 'rider',
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.table.columns.createdBy'
          ),
          width: '40%',
          sort: 'date',
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.table.columns.createdAt'
          ),
          width: '30%',
          sort: 'date',
        },
      ],
      opened: [],

      addEdit: {
        model: '',
      },
    }
  },

  computed: {
    reqId() {
      return this.$route.params.id
    },

    getEndpoint() {
      return useEndpoints.note.index(this.reqId, 'bike')
    },
  },

  methods: {
    truncate,
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    notify({
      title = 'Create Note',
      text = 'Note successfully created',
      type = 'success',
      duration = 3000,
    }) {
      this.$notify(
        {
          group: 'bottomRight',
          type,
          title,
          text,
        },
        duration
      )
    },

    onAddEdit({ mode }) {
      console.log(mode)
      this.$modal.show('addEditNote')
    },

    onAddEditOpened() {
      const c = setTimeout(() => {
        this.$refs.sweetMarkRef.focus()
        clearTimeout(c)
      }, 100)
    },

    async onSaveNote({ note, entityId, entityName = 'bike' }) {
      console.log(note, entityId, entityName)
      const data = new FormData()
      data.append('details', note)

      await this.$http
        .post(useEndpoints.note.create(entityId, entityName), data)
        .then(async (res) => {
          console.log(res.data)
          this.addEdit.model = ''
          this.notify({ type: 'success' })
          this.$modal.hide('addEditNote')
          await this.$store.dispatch('fsTable/fetchData')
        })
        .catch((err) => {
          const e = {
            type: 'danger',
            title: 'Failed to Add Note',
            text: err.response || 'Server error occured!',
          }
          console.log(err.response)
          this.notify(e)
        })
    },

    async onDelete({ noteId }) {
      if (this.$acl.canNotDelete('vehicles')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'delete',
            permission: 'notes',
          })
        )
        return
      }
      const url = useEndpoints.note.delete(noteId)
      await this.$http.delete(url).then(async () => {
        this.notify({ title: 'Delete Note', text: 'Note successfully deleted' })
        await this.$store.dispatch('fsTable/fetchData')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
</style>
