var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded"},[_c('div',{staticClass:"px-4 mt-2"},[_c('TitlePlus',{staticClass:"mb-10",attrs:{"title":_vm.$t('components.vehicleDetailsManagement.diveIntoTab.notes.headline'),"hide-plus":_vm.$acl.canNotCreate('vehicles')},on:{"plus":function($event){return _vm.onAddEdit({ mode: 'add' })}}})],1),_c('div',{staticClass:"flex flex-col px-4 mt-4 mb-10 md:items-center md:flex-row"},[_c('SummaryCard',{attrs:{"title":_vm.$t(
          'components.vehicleDetailsManagement.diveIntoTab.notes.summary.totalNotes'
        ),"value":_vm.indexMetaData.count.total,"variant":"gray"}})],1),[_c('div',{staticClass:"full-mode"},[_c('FSTable',{attrs:{"fst-id":"vehicleNotes","endpoint":_vm.getEndpoint,"headers":_vm.tableHeaders},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var data = ref.data;
return _vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5}}),_c('FSTableRowItem',{attrs:{"text":item.details}}),_c('FSTableRowItem',{attrs:{"text":item.created_by.full_name}}),_c('FSTableRowItem',{attrs:{"text":item.created_by.role_name}}),_c('FSTableRowItem',{attrs:{"text":item.created_at,"date":""}}),_c('FSTableRowItem',[_c('div',{staticClass:"flex justify-end"},[_c('TDropdown',{key:("actions-" + (item.id)),attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                  var mousedownHandler = ref.mousedownHandler;
                  var focusHandler = ref.focusHandler;
                  var blurHandler = ref.blurHandler;
                  var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"w-5 app-form-reset",attrs:{"aria-label":"Report Status Menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('i',{staticClass:"text-sm text-gray-400 fas fa-ellipsis-v"})])]}},{key:"default",fn:function(){return [_c('div',{staticClass:"py-1 bg-white rounded shadow"},[_c('div',{staticClass:"py-2 px-4 text-sm text-red-500 cursor-pointer hover:bg-red-100",on:{"click":function($event){return _vm.onDelete({ noteId: item.id })}}},[_vm._v(" Delete ")])])]},proxy:true}],null,true)})],1)])],1)})}}])})],1),_c('div',{staticClass:"responsive-mode"},[_c('FSTable',{attrs:{"fst-id":"vehicleNotes","endpoint":_vm.getEndpoint,"headers":_vm.resTableHeaders},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',{attrs:{"text":item.details}}),_c('FSTableRowItem',{attrs:{"text":item.created_by.full_name}}),_c('FSTableRowItem',{attrs:{"text":item.created_at,"date":""}})],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Id")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.truncate(item.id, -5, '#') || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Created Role")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.created_by.role_name || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Actions")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex justify-start"},[_c('TDropdown',{key:("actions-" + (item.id)),attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                  var mousedownHandler = ref.mousedownHandler;
                  var focusHandler = ref.focusHandler;
                  var blurHandler = ref.blurHandler;
                  var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"w-5 app-form-reset",attrs:{"aria-label":"Report Status Menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('i',{staticClass:"text-sm text-gray-400 fas fa-ellipsis-v"})])]}},{key:"default",fn:function(){return [_c('div',{staticClass:"py-1 bg-white rounded shadow"},[_c('div',{staticClass:"py-2 px-4 text-sm text-red-500 cursor-pointer hover:bg-red-100",on:{"click":function($event){return _vm.onDelete({ noteId: item.id })}}},[_vm._v(" Delete ")])])]},proxy:true}],null,true)})],1)])])])]):_vm._e()]})]}}])})],1)],_c('TModal',{attrs:{"name":"addEditNote","variant":"popupAlt"},on:{"opened":_vm.onAddEditOpened}},[_c('div',{staticClass:"pl-4 mt-4 text-lg font-bold"},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleDetailsManagement.diveIntoTab.notes.addEdit.title' ))+" ")]),_c('div',{staticClass:"px-4 pt-10 pb-12"},[_c('SweetMark',{ref:"sweetMarkRef",attrs:{"placeholder":_vm.$t(
            'components.vehicleDetailsManagement.diveIntoTab.notes.addEdit.placeholder'
          ),"persistent-button":""},on:{"save":function($event){return _vm.onSaveNote({ note: $event, entityId: _vm.reqId, entityName: 'bike' })}},model:{value:(_vm.addEdit.model),callback:function ($$v) {_vm.$set(_vm.addEdit, "model", $$v)},expression:"addEdit.model"}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }